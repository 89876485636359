export const colors = [
	'#264653',
	'#2a9d8f',
	'#e9c46a',
	'#f4a261',
	'#e76f51',
	'#e63946',
	'#a8dadc',
	'#457b9d',
	'#1d3557',
	'#005f73',
	'#0a9396',
	'#94d2bd',
	'#ee9b00',
	'#ca6702',
	'#bb3e03',
	'#ae2012',
	'#9b2226',
	'#023e8a',
	'#0077b6',
	'#0096c7',
	'#00b4d8',
	'#48cae4',
	'#90e0ef',
	'#99d98c',
	'#34a0a4',
	'#168aad',
	'#ffc300',
	'#b5179e',
	'#560bad',
	'#480ca8',
	'#800f2f',
	'#a4133c',
	'#c9184a',
	'#ff758f',
	'#74c69d',
	'#52b788',
	'#40916c',
	'#ff9f1c',
	'#ffbf69',
	'#2ec4b6',
	'#008000',
	'#f9c74f',
	'#7b2cbf',
	'#82c0cc',
	'#9c1057',
	'#00a896',
	'#ff8a5b',
	'#9d4edd',
	'#d81159',
	'#c5d86d',
];
